import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
import _values from "lodash-es/values";
import _merge from "lodash-es/merge";
import _keyBy from "lodash-es/keyBy";

var _templateObject;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { Redirect } from '@reach/router';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ArtistArtworks } from '@/artist/components/artist-artworks';
import { ArtistExhibitions } from '@/artist/components/artist-exhibitions';
import { ArtistPersonalInfo } from '@/artist/components/artist-personal-info';
import { ArtistPortfolio } from '@/artist/components/artist-portfolio';
import { ArtistProfileImage } from '@/artist/components/artist-profile-image';
import { BackLink } from '@/common/components/breadcrumb';
import { Content } from '@/common/components/content/content-element';
import { Layout } from '@/common/components/layout';
import { ScrollRestore } from '@/common/components/scroll-restore';
import { SEO } from '@/common/components/seo';
import { useAppInfo, useTranslation, useUri } from '@/common/hooks';
import { ArtistContainer, ArtistDescription, ArtistDetailsContainer, ArtistElementsBase, ArtistExternalLinksStyled, ArtistProfileDetails } from './artist';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "4214624276";
var GalleryProfileQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query GalleryProfileQuery($id: ID!) {\n    GalleryProfile: node(id: $id) {\n      ... on GalleryProfile {\n        hostedArtworks {\n          nodes {\n            id\n            editionAvailableCount\n            editionSalesCount\n            editionPendingCount\n            editionSize\n            purchasePrice {\n              amount\n              formattedValue\n            }\n            isBuyable\n          }\n        }\n      }\n    }\n  }\n"])));

var GalleryPage = function GalleryPage(_ref) {
  var _galleryProfile$id, _galleryProfile$image, _galleryProfile$image2, _galleryProfile$image3, _galleryProfile$image4, _galleryProfile$image5, _galleryProfile$image6, _galleryProfile$descr, _galleryProfile$descr2, _galleryProfile$artwo3, _galleryProfile$artwo4, _galleryProfile$hoste;

  var data = _ref.data;

  var _useAppInfo = useAppInfo(),
      defaultMetaDescription = _useAppInfo.defaultMetaDescription;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  var _data$moonaapi = data === null || data === void 0 ? void 0 : data.moonaapi,
      galleryProfile = _data$moonaapi.galleryProfile;

  var _useQuery = useQuery(GalleryProfileQuery, {
    variables: {
      id: (_galleryProfile$id = galleryProfile === null || galleryProfile === void 0 ? void 0 : galleryProfile.id) !== null && _galleryProfile$id !== void 0 ? _galleryProfile$id : ''
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  }),
      galleryProfileData = _useQuery.data,
      refetch = _useQuery.refetch;

  if (!galleryProfile) {
    return ___EmotionJSX(Redirect, {
      to: "/404",
      noThrow: true
    });
  } // eslint-disable-next-line react-hooks/rules-of-hooks


  var galleryArtworks = useMemo(function () {
    if (!galleryProfileData) {
      var _galleryProfile$artwo;

      return (galleryProfile === null || galleryProfile === void 0 ? void 0 : (_galleryProfile$artwo = galleryProfile.artworks) === null || _galleryProfile$artwo === void 0 ? void 0 : _galleryProfile$artwo.nodes) || [];
    } else {
      var _galleryProfile$artwo2, _galleryProfileData$G;

      var mergedArtworks = _merge(_keyBy(galleryProfile === null || galleryProfile === void 0 ? void 0 : (_galleryProfile$artwo2 = galleryProfile.artworks) === null || _galleryProfile$artwo2 === void 0 ? void 0 : _galleryProfile$artwo2.nodes, 'id'), _keyBy(galleryProfileData === null || galleryProfileData === void 0 ? void 0 : (_galleryProfileData$G = galleryProfileData.GalleryProfile) === null || _galleryProfileData$G === void 0 ? void 0 : _galleryProfileData$G.hostedArtworks.nodes, 'id'));

      return _values(mergedArtworks);
    }
  }, [galleryProfile, galleryProfileData]);
  var meta = [{
    property: 'og:url',
    content: uriHelper.ensureAbsoluteUrl(uriHelper.getGalleryUrl(galleryProfile.slug))
  }, {
    property: 'og:image',
    content: CONFIG.appUrl + (((_galleryProfile$image = galleryProfile.image) === null || _galleryProfile$image === void 0 ? void 0 : (_galleryProfile$image2 = _galleryProfile$image.childImageSharp) === null || _galleryProfile$image2 === void 0 ? void 0 : (_galleryProfile$image3 = _galleryProfile$image2.fluid) === null || _galleryProfile$image3 === void 0 ? void 0 : _galleryProfile$image3.src) || '')
  }, {
    property: 'twitter:image',
    content: CONFIG.appUrl + (((_galleryProfile$image4 = galleryProfile.image) === null || _galleryProfile$image4 === void 0 ? void 0 : (_galleryProfile$image5 = _galleryProfile$image4.childImageSharp) === null || _galleryProfile$image5 === void 0 ? void 0 : (_galleryProfile$image6 = _galleryProfile$image5.fluid) === null || _galleryProfile$image6 === void 0 ? void 0 : _galleryProfile$image6.src) || '')
  }, {
    property: 'og:description',
    content: (_galleryProfile$descr = galleryProfile.description) !== null && _galleryProfile$descr !== void 0 ? _galleryProfile$descr : defaultMetaDescription
  }, {
    name: 'twitter:description',
    content: (_galleryProfile$descr2 = galleryProfile.description) !== null && _galleryProfile$descr2 !== void 0 ? _galleryProfile$descr2 : defaultMetaDescription
  }];
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: galleryProfile.galleryName || t('performanceData.gallery'),
    meta: meta,
    description: galleryProfile.description || defaultMetaDescription
  }), ___EmotionJSX(ScrollRestore, {
    uri: uriHelper.getGalleryUrl(galleryProfile.slug)
  }), ___EmotionJSX(ArtistElementsBase, null, ___EmotionJSX(ArtistContainer, null, ___EmotionJSX(BackLink, {
    to: uriHelper.getExhibitionsUrl()
  }, ___EmotionJSX(FormattedMessage, {
    id: "general.backLinks.backToExhibitionsOverview"
  })), ___EmotionJSX(ArtistDetailsContainer, null, (galleryProfile === null || galleryProfile === void 0 ? void 0 : galleryProfile.image) && ___EmotionJSX(ArtistProfileImage, {
    artistName: galleryProfile.galleryName || t('performanceData.gallery'),
    profileImage: galleryProfile.image
  }), ___EmotionJSX(ArtistProfileDetails, null, ___EmotionJSX(ArtistPersonalInfo, {
    artistProfiles: galleryProfile
  }), ___EmotionJSX(ArtistExternalLinksStyled, {
    links: galleryProfile === null || galleryProfile === void 0 ? void 0 : galleryProfile.externalLinks
  }), ___EmotionJSX(ArtistPortfolio, {
    portfolio: (_galleryProfile$artwo3 = galleryProfile === null || galleryProfile === void 0 ? void 0 : (_galleryProfile$artwo4 = galleryProfile.artworks) === null || _galleryProfile$artwo4 === void 0 ? void 0 : _galleryProfile$artwo4.nodes) !== null && _galleryProfile$artwo3 !== void 0 ? _galleryProfile$artwo3 : []
  }), ___EmotionJSX(ArtistDescription, null, ___EmotionJSX(Content, {
    source: galleryProfile.description
  }))))), ___EmotionJSX(ArtistExhibitions, {
    exhibitions: galleryProfile === null || galleryProfile === void 0 ? void 0 : (_galleryProfile$hoste = galleryProfile.hostedExhibitions) === null || _galleryProfile$hoste === void 0 ? void 0 : _galleryProfile$hoste.nodes,
    curatorName: galleryProfile.galleryName
  }), ___EmotionJSX(ArtistArtworks, {
    artworks: galleryArtworks,
    name: galleryProfile.galleryName,
    presentedBy: true,
    handleRefetchArtworks: refetch
  })));
};

GalleryPage.displayName = "GalleryPage";
export { GalleryPage as default, query };